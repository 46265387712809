<template>
  <StartSection v-if="getUserValues != null" />
  <PreStartSection v-if="getUserValues == null" />
</template>

<script>
import PreStartSection from "@/components/PreStartSection.vue";
import StartSection from "@/components/StartSection.vue";

export default {
  name: "StartView",
  components: {
    StartSection,
    PreStartSection,
  },
  computed: {
    getLoading() {
      return this.$store.getters.getLoading;
    },
    getUserValues() {
      return this.$store.getters.getUserValues;
    },
  },
  created() {
    this.$store.commit("setUserCode", this.$route.params.code);
    this.$store.dispatch("fetchUser");
    this.$store.dispatch("fetchQuestions");
  },
};
</script>

<style lang="css" scoped>
.skill-section {
  background-color: #faf5e6;
}
</style>
