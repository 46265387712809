<template>
  <div class="container" v-if="getUserValues && getPageContent">
    <div class="row">
      <div
        ref="elementToMeasure"
        class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12 offset-sm-0"
      >
        <section class="start-section p-4 text-center">
          <LogoNavBar />
          <h1 class="text-white">
            <strong>{{ getUserValues.name }}</strong>
            <span v-html="getPageContent.name_text"></span>
          </h1>
          <img class="text-center p-5" src="@/assets/podium.svg" alt="Speelr" />
          <div
            class="pt-3 text-white"
            v-html="convertRelativeUrlsToAbsolute(getPageContent.intro_text)"
          ></div>
          <a
            class="btn btn-lg btn-primary text-white mt-4 ps-5 pe-5"
            href=""
            @click="gotoQuestions()"
            >SWIPE & WIN!</a
          >
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import LogoNavBar from "@/components/LogoNavBar.vue";

export default {
  name: "StartSection",
  components: {
    LogoNavBar,
  },
  created() {
    this.$store.commit("setPageType", "intro");
    this.$store.dispatch("fetchPageContent");
  },
  computed: {
    getUserValues() {
      return this.$store.getters.getUserValues;
    },
    getPageContent() {
      return this.$store.getters.getPageContent;
    },
  },
  methods: {
    gotoQuestions() {
      this.$router.push("/question");
    },
    convertRelativeUrlsToAbsolute(html) {
      let baseUrl = process.env.VUE_APP_ASSETURL;
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const images = doc.querySelectorAll("img");

      images.forEach((img) => {
        const src = img.getAttribute("src");
        if (src && !src.startsWith("http")) {
          // Check if the URL is relative
          img.setAttribute("src", `${baseUrl}${src}`);
        }
      });

      return doc.body.innerHTML;
    },
  },
};
</script>

<style>
h1 {
  font-weight: 100;
}
.start-section {
  /* padding-top: 70px; */
  border-radius: 0 0 25px 25px;
  background: linear-gradient(
    0deg,
    rgba(244, 162, 225, 1) 0%,
    rgba(255, 174, 103, 1) 70%
  );
}
</style>
