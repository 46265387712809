<template>
  <section class="footer-section">
    <div class="container">
      <nav class="navbar fixed-bottom">
        <div class="container-fluid text-center">
          <div class="container">
            <div class="row">
              <div
                class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12 offset-sm-0"
              >
                <div class="container pb-4">
                  <div class="row align-items-center">
                    <div class="col-3">
                      <!-- <img src="@/assets/footer_previous.svg" /> -->
                    </div>
                    <div class="col-3" @click="setAnswer(false)">
                      <img src="@/assets/footer_false.svg" />
                    </div>
                    <div class="col-3" @click="setAnswer(true)">
                      <img src="@/assets/footer_true.svg" />
                    </div>
                    <div class="col-3">
                      <!-- <img src="@/assets/footer_next.svg" /> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
export default {
  name: "PlanetSection",
  components: {},
  computed: {
    getActiveQuestionId() {
      return this.$store.getters.getActiveQuestionId;
    },
  },
  methods: {
    setAnswer(answer) {
      if (answer) {
        this.$store.commit("setPressedWin", false);
        this.$store.commit("setPressedWin", true);
      } else {
        this.$store.commit("setPressedLoose", false);
        this.$store.commit("setPressedLoose", true);
      }
      this.$store.dispatch("postAnswer", answer);
    },
  },
};
</script>

<style scoped>
.footer-section {
  background-color: #f5f9f0;
}
.footer-container {
  padding: 2em;
}
.overlay {
  position: absolute;
  visibility: visible;
}
.nav-tabs .nav-link.active {
  background-color: #f5f9f0 !important;
  border-bottom-color: #f5f9f0;
}
.logo {
  filter: brightness(0) saturate(100%) invert(9%) sepia(29%) saturate(6725%)
    hue-rotate(231deg) brightness(85%) contrast(94%);
}
</style>
