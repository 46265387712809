<template>
  <ContactSection v-if="getPageContent" />
</template>

<script>
import ContactSection from "@/components/ContactSection.vue";

export default {
  name: "StartView",
  components: {
    ContactSection,
  },
  created() {
    this.$store.commit("setPageType", "contact");
    this.$store.dispatch("fetchPageContent");
  },
  computed: {
    getPageContent() {
      return this.$store.getters.getPageContent;
    },
  },
};
</script>

<style lang="css" scoped>
.skill-section {
  background-color: #faf5e6;
}
</style>
