<template>
  <div class="container" v-if="getPageContent">
    <div class="row">
      <div
        ref="elementToMeasure"
        class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12 offset-sm-0"
      >
        <section class="podium-section p-4 text-center">
          <NavBar />
          <div
            class="pt-3 text-white"
            v-html="convertRelativeUrlsToAbsolute(getPageContent.content)"
          ></div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  name: "PodiumSection",
  components: {
    NavBar,
  },
  data() {
    return {
      baseUrl: "https://example.com", // Your base URL
    };
  },
  computed: {
    getPageContent() {
      return this.$store.getters.getPageContent;
    },
  },
  methods: {
    gotoQuestions() {
      this.$router.push("/question");
    },
    convertRelativeUrlsToAbsolute(html) {
      let baseUrl = process.env.VUE_APP_ASSETURL;
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const images = doc.querySelectorAll("img");

      images.forEach((img) => {
        const src = img.getAttribute("src");
        if (src && !src.startsWith("http")) {
          // Check if the URL is relative
          img.setAttribute("src", `${baseUrl}${src}`);
        }
      });

      return doc.body.innerHTML;
    },
  },
};
</script>

<style>
h1 {
  font-weight: 100;
}
.podium-section {
  padding-top: 100px !important;
  border-radius: 0 0 25px 25px;
  background: linear-gradient(
    0deg,
    rgba(244, 162, 225, 1) 0%,
    rgba(255, 174, 103, 1) 70%
  );
}
</style>
