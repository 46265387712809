import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import StartView from "@/views/StartView.vue";
import QuestionView from "@/views/QuestionView.vue";
import PodiumView from "@/views/PodiumView.vue";
import ContactView from "@/views/ContactView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/c/:code",
    name: "start",
    component: StartView,
  },
  {
    path: "/question",
    name: "question",
    component: QuestionView,
  },
  {
    path: "/podium",
    name: "podium",
    component: PodiumView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Using the variables to satisfy ESLint, even if not directly utilized
    if (to || from || savedPosition) {
      // This block is intentionally left blank as the variables are not needed here
    }
    return { top: 0 }; // Scrolls to the top of the page on route change
  },
});

export default router;
