<template>
  <div class="container" v-if="getPageContent">
    <div class="row">
      <div
        ref="elementToMeasure"
        class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12 offset-sm-0"
      >
        <section class="prestart-section p-4 pb-3 text-center">
          <LogoNavBar />
          <div
            class="pt-3 text-white"
            v-html="convertRelativeUrlsToAbsolute(getPageContent.content)"
          ></div>
          <div class="mb-3">
            <input
              type="text"
              class="form-control form-control-lg text-center"
              id="exampleFormControlInput1"
              placeholder="CODE"
              v-model="code"
            />
            <div class="d-grid gap-2">
              <button
                type="button"
                class="btn btn-primary btn-lg text-white mt-2"
                :class="[code ? 'inactive' : 'active']"
                @click="checkcode()"
                :disabled="!code"
              >
                Send
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import LogoNavBar from "@/components/LogoNavBar.vue";

export default {
  name: "StartSection",
  components: {
    LogoNavBar,
  },
  computed: {
    getPageContent() {
      return this.$store.getters.getPageContent;
    },
  },
  data: () => ({
    code: "",
  }),
  created() {
    this.$store.commit("setPageType", "home");
    this.$store.dispatch("fetchPageContent");
  },
  methods: {
    checkcode() {
      this.$router.push("/c/" + this.code.toUpperCase());
    },
    convertRelativeUrlsToAbsolute(html) {
      let baseUrl = process.env.VUE_APP_ASSETURL;
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const images = doc.querySelectorAll("img");

      images.forEach((img) => {
        const src = img.getAttribute("src");
        if (src && !src.startsWith("http")) {
          // Check if the URL is relative
          img.setAttribute("src", `${baseUrl}${src}`);
        }
      });

      return doc.body.innerHTML;
    },
  },
};
</script>

<style>
input {
  text-transform: uppercase;
}
h1 {
  font-weight: 100;
}
.prestart-section {
  /* padding-top: 70px; */
  border-radius: 0 0 25px 25px;
  background: linear-gradient(
    0deg,
    rgba(244, 162, 225, 1) 0%,
    rgba(255, 174, 103, 1) 70%
  );
}
</style>
