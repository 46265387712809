<template>
  <div>
    <Transition name="fade" @after-leave="onLeave">
      <div v-if="getPressedLoose" class="overlay">
        <img
          class="text-center p-3 ster"
          src="@/assets/pressedLoose.svg"
          alt="Speelr"
        />
      </div>
    </Transition>
    <button @click="toggleOverlay">Toggle Overlay</button>
  </div>
</template>

<script>
export default {
  computed: {
    getPressedLoose() {
      return this.$store.getters.getPressedLoose;
    },
  },
  watch: {
    getPressedLoose(newVal) {
      if (newVal) {
        this.startTimer();
      }
    },
  },
  methods: {
    toggleOverlay() {
      this.$store.commit("setPressedLoose", !this.getPressedLoose);
    },
    startTimer() {
      setTimeout(() => {
        this.$store.commit("setPressedLoose", false);
      }, 1000);
    },
    onLeave() {
      console.log("Overlay has been hidden");
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(244, 162, 225, 1) 0%,
    rgba(255, 174, 103, 1) 70%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500; /* ensure it is on top of other content */
}

.ster {
  animation: ster 0.5s linear infinite;
  transform-box: fill-box;
  transform-origin: center;
}
@keyframes ster {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
</style>
