<template>
  <PreStartSection />
</template>

<script>
import PreStartSection from "@/components/PreStartSection.vue";

export default {
  name: "HomeView",
  components: {
    PreStartSection,
  },
  computed: {},
  created() {},
};
</script>

<style lang="css" scoped>
.skill-section {
  background-color: #faf5e6;
}
</style>
