<template>
  <div class="container" v-if="getUserValues">
    <PressedWin />
    <PressedLoose />
    <div class="row">
      <div
        class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12 offset-sm-0"
      >
        <section class="question-section p-4">
          <NavBar />
          <div
            class="progress bg-secondary mb-2"
            role="progressbar"
            aria-label="question-progress"
            :aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
            style="height: 7px"
          >
            <div
              class="progress-bar"
              :style="'width:' + (100 / 15) * getQuestionId + '%'"
            ></div>
          </div>

          <ol :start="getQuestionId">
            <li>
              <h3 class="text-white">
                {{ getQuestion.question }}
              </h3>
            </li>
          </ol>

          <div
            class="card"
            v-touch:swipe="onSwipeItem()"
            @touchstart="onTouchStart"
            @touchmove="onTouchMove"
            @touchend="onTouchEnd"
          >
            <img
              :src="
                'https://db.speelr.nl/storage/uploads' + getQuestion.image.path
              "
              class="card-img"
              :alt="getQuestion.caption_1"
              v-if="getQuestion.image"
            />
            <div class="card-img-overlay d-flex flex-column align-items-start">
              <div class="mt-auto">
                <h5 class="card-title">
                  <strong>{{ getQuestion.caption_1 }}</strong>
                </h5>
                <p class="card-text">{{ getQuestion.caption_2 }}</p>
              </div>
            </div>
          </div>
          <FooterSection />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterSection from "@/components/FooterSection.vue";
import PressedWin from "@/components/PressedWin.vue";
import PressedLoose from "@/components/PressedLoose.vue";

export default {
  name: "QuestionSection",
  components: {
    NavBar,
    FooterSection,
    PressedWin,
    PressedLoose,
  },
  computed: {
    getUserValues() {
      return this.$store.getters.getUserValues;
    },
    getQuestionId() {
      let activeQuestionId = this.$store.getters.getActiveQuestionId;
      return activeQuestionId + 1;
    },
    getQuestion() {
      let activeQuestionId = this.$store.getters.getActiveQuestionId;
      let questions = this.$store.getters.getQuestions;
      return questions[activeQuestionId];
    },
  },
  methods: {
    getAppUrl() {
      let url = process.env.VUE_APP_URL;
      return url;
    },
    onSwipeItem() {
      return function (direction) {
        if (direction === "left") {
          this.$store.commit("setPressedLoose", true);
          this.$store.dispatch("postAnswer", false);
        } else if (direction === "right") {
          this.$store.commit("setPressedWin", true);
          this.$store.dispatch("postAnswer", true);
        }
      }.bind(this);
    },
    setAnswer(answer) {
      this.$store.dispatch("postAnswer", answer);
    },
  },
};
</script>

<style>
h1,
h2,
h3,
h4,
h5 {
  font-weight: 100;
}
.question-section {
  padding-top: 100px !important;
  border-radius: 0 0 25px 25px;
  background: linear-gradient(
    0deg,
    rgba(244, 162, 225, 1) 0%,
    rgba(255, 174, 103, 1) 70%
  );
}
ol {
  font-size: calc(1.3rem + 0.6vw);
  color: white;
}
.card-title,
.card-text {
  filter: drop-shadow(0px 0px 7px #000000);
  color: #fff;
}
.card {
  border: none;
  border-radius: 20px !important;
  aspect-ratio: auto 1/1;
  will-change: transform;
  transition: all 0.3s ease-in-out;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}
.card-img {
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  aspect-ratio: auto 1/1;
  border-radius: 20px !important;
  filter: saturate(70%);
}
</style>
