<template>
  <main>
    <router-view :key="$route.path" />
  </main>
</template>

<script>
export default {
  components: {},
  computed: {
    getLoading() {
      return this.$store.getters.getLoading;
    },
  },
};
</script>

<style lang="scss">
@import "styles/custom.scss";

@import "bootstrap/scss/bootstrap.scss";
// @import '~bootstrap-vue/src/index.scss';

.heartbeat {
  animation: heartbeat 1s infinite alternate ease-in-out;
  transform-origin: center;
  transform-box: fill-box;
}

/* Heart beat animation */
@keyframes heartbeat {
  to {
    transform: scale(0.8);
  }
}
.cloud {
  animation: cloud 15s infinite alternate ease-in-out;
}
@keyframes cloud {
  0%,
  100% {
    transform: translateX(5%);
  }
  50% {
    transform: translateX(-5%);
  }
}
.ster {
  animation: ster 5s linear infinite;
  transform-box: fill-box;
  transform-origin: center;
}
@keyframes ster {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
.swing {
  animation: swinging 1.5s infinite alternate ease-in-out;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes swinging {
  from {
    transform: rotateZ(-5deg);
  }
  to {
    transform: rotate(3deg);
  }
}
.downup {
  animation: updown 7s infinite ease-in-out;
  animation-direction: reverse;
  transform-origin: center;
  transform-box: fill-box;
}
.updown {
  animation: updown 15s infinite ease-in-out;
  transform-origin: center;
  transform-box: fill-box;
}
@keyframes updown {
  0%,
  100% {
    transform: translateY(-6%);
  }
  50% {
    transform: translateY(4%);
  }
}
</style>
