<template lang="html">
  <section class="pt-3 pb-2">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12 offset-sm-0 text-center"
        >
          <img
            class="text-center"
            src="@/assets/logo.svg"
            alt="Speelr"
            width="250"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    width: Number,
  },
};
</script>

<style lang="css" scoped>
.fixed-top-custom {
  position: fixed;
  top: 0;
  z-index: 1030;
}
</style>
