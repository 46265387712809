<template>
  <QuestionSection v-if="getUserValues != null && getActiveQuestionId < 15" />
  <HoorahSection v-if="getUserValues != null && getActiveQuestionId > 14" />
</template>

<script>
import QuestionSection from "../components/QuestionSection.vue";
import HoorahSection from "../components/HoorahSection.vue";

export default {
  name: "QuestionView",
  components: {
    QuestionSection,
    HoorahSection,
  },
  computed: {
    getLoading() {
      return this.$store.getters.getLoading;
    },
    getUserValues() {
      return this.$store.getters.getUserValues;
    },
    getActiveQuestionId() {
      return this.$store.getters.getActiveQuestionId;
    },
  },
  created() {
    console.log(this.getActiveQuestionId);
  },
};
</script>

<style lang="css" scoped>
.skill-section {
  background-color: #faf5e6;
}
</style>
