<template lang="html">
  <section class="navbar-section">
    <div class="container">
      <nav class="navbar fixed-top bg-body-tertiary">
        <div class="container-fluid text-center">
          <div class="container">
            <div class="row">
              <div
                class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12 offset-sm-0"
              >
                <div class="container pt-3 pb-3">
                  <div class="row align-items-center">
                    <div class="col">
                      <router-link :to="'/question'">
                        <img
                          src="@/assets/nav_logo_active.svg"
                          height="40"
                          alt=""
                          v-if="currentRouteName == 'question'"
                        />
                        <img
                          src="@/assets/nav_logo_inactive.svg"
                          height="40"
                          alt=""
                          v-if="currentRouteName != 'question'"
                        />
                      </router-link>
                    </div>
                    <div class="col">
                      <router-link :to="'/podium'">
                        <img
                          src="@/assets/nav_podium_active.svg"
                          height="40"
                          alt=""
                          v-if="currentRouteName == 'podium'"
                        />
                        <img
                          src="@/assets/nav_podium_inactive.svg"
                          height="40"
                          alt=""
                          v-if="currentRouteName != 'podium'"
                        />
                      </router-link>
                    </div>
                    <div class="col">
                      <router-link :to="'/contact'">
                        <img
                          src="@/assets/nav_chat_active.svg"
                          height="40"
                          alt=""
                          v-if="currentRouteName == 'contact'"
                        />
                        <img
                          src="@/assets/nav_chat_inactive.svg"
                          height="40"
                          alt=""
                          v-if="currentRouteName != 'contact'"
                        />
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
export default {
  name: "NavBar",
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="css" scoped>
.navbar-section {
  background-color: #f5f9f0;
}
</style>
