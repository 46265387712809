import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";
// import router from "../router";

const vuexLocalStorage = new VuexPersistence({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: (state) => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default createStore({
  plugins: [vuexLocalStorage.plugin],
  state: {
    loading: false,
    userCode: null,
    userValues: null,
    questions: [],
    activeQuestionid: 0,
    homeContent: "",
    podiumContent: "",
    contactContent: "",
    pageType: null,
    pageContent: null,
    pressedWin: false,
    pressedLoose: false,
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getActiveYear(state) {
      return state.activeYear;
    },
    getUserCode(state) {
      return state.userCode;
    },
    getUserValues(state) {
      return state.userValues;
    },
    getQuestions(state) {
      return state.questions;
    },
    getActiveQuestionId(state) {
      return state.activeQuestionid;
    },
    getHomeContent(state) {
      return state.homeContent;
    },
    getPodiumContent(state) {
      return state.podiumContent;
    },
    getContactContent(state) {
      return state.contactContent;
    },
    getPageType(state) {
      return state.pageType;
    },
    getPageContent(state) {
      return state.pageContent;
    },
    getPressedWin(state) {
      return state.pressedWin;
    },
    getPressedLoose(state) {
      return state.pressedLoose;
    },
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },
    setUserCode(state, value) {
      state.userCode = value;
    },
    setUserValues(state, value) {
      state.userValues = value;
    },
    setQuestions(state, value) {
      state.questions = value;
    },
    setActiveQuestionId(state, value) {
      state.activeQuestionid = value;
    },
    setHomeContent(state, value) {
      state.homeContent = value;
    },
    setPodiumContent(state, value) {
      state.podiumContent = value;
    },
    setContactContent(state, value) {
      state.contactContent = value;
    },
    setPageType(state, value) {
      state.pageType = value;
    },
    setPageContent(state, value) {
      state.pageContent = value;
    },
    setPressedWin(state, value) {
      state.pressedWin = value;
    },
    setPressedLoose(state, value) {
      state.pressedLoose = value;
    },
  },
  actions: {
    fetchUser(state) {
      state.commit("setLoading", true);
      let code = state.getters.getUserCode;
      axios
        .get("https://scripts.speelr.nl/get_user.php?code=" + code)
        .then((response) => {
          // console.log(response.data);
          // console.log(code);
          state.commit("setUserValues", response.data);
          state.commit("setLoading", false);
        })
        .catch((err) => {
          state.commit("setLoading", false);
          state.commit("setUserCode", null);
          state.commit("setUserValues", null);
          console.log(err);
        });
    },
    fetchQuestions(state) {
      state.commit("setLoading", true);
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      axios
        .get(cockpiturl + "/content/items/questions", {
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          state.commit("setQuestions", response.data);
          state.commit("setLoading", false);
        })
        .catch((err) => console.log(err));
    },
    fetchPageContent(state) {
      state.commit("setPageContent", null);
      state.commit("setLoading", true);
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      let pagetype = state.getters.getPageType;
      axios
        .get(cockpiturl + "/content/item/" + pagetype, {
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          state.commit("setPageContent", response.data);
          state.commit("setLoading", false);
        })
        .catch((err) => console.log(err));
    },
    postAnswer(state, answer) {
      state.commit("setLoading", true);
      let token = process.env.VUE_APP_TOKEN;
      let apiurl = "https://db.speelr.nl/api/content/item/users";
      // let answer_id = state.getters.getActiveQuestionId + 1;

      let data = {};
      data._id = state.getters.getUserValues._id;
      let propertyName = `answer_${state.getters.getActiveQuestionId + 1}`;
      data[propertyName] = answer;

      // data["answer_${state.getters.getActiveQuestionId + 1}"] = answer;

      axios
        .post(
          apiurl,
          { data },
          {
            headers: {
              "api-key": token,
            },
          }
        )
        .then(() => {
          // console.log(response.data);
          state.commit(
            "setActiveQuestionId",
            state.getters.getActiveQuestionId + 1
          );
          state.commit("setLoading", false);
          // state.commit("setPressedWin", false);
          // state.commit("setPressedLoose", false);
        })
        .catch((err) => console.log(err));
    },
  },
  modules: {},
});
