<template>
  <PodiumSection v-if="getPageContent" />
</template>

<script>
import PodiumSection from "@/components/PodiumSection.vue";

export default {
  name: "StartView",
  components: {
    PodiumSection,
  },
  created() {
    this.$store.commit("setPageType", "podium");
    this.$store.dispatch("fetchPageContent");
  },
  computed: {
    getPageContent() {
      return this.$store.getters.getPageContent;
    },
  },
};
</script>

<style lang="css" scoped>
.skill-section {
  background-color: #faf5e6;
}
</style>
